
import { Component, Vue } from 'vue-property-decorator'

@Component

export default class OrdinarySeedlingAdd extends Vue {
  private activeName = 'sceneryDetail'

  created () {
    this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
  }

  changeTab (val: {name: string}) {
    this.$router.push({
      path: `/sceneryList/detail${this.$route.params.sceneryId}/${val.name}`
    })
  }
}
